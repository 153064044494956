<template>
  <div id='navbar'>
    <div class="navbar__wrapper" :class="{ 'scrolled': isScrolled }">
      <div :class="[
        'navbar__logo',
        { 'nabar__logo--visible': isLogoVisible },
        { 'mobile-menu-opened' : isMobileMenuOpen },
      ]">
        <a @click="handleLogoClick">
          <img src="/images/logos/sysCops.svg">
        </a>
      </div>

      <div v-if="links" class="navbar__links">
        <ul class="navbar-links">
          <li class="navbar-links__item" v-for="(item, i) in links" :key="i">
            <span v-if="item.isGoToBottom" @click="goToLastSection">{{ item.name }}</span>
            <router-link v-else :to="item.link">
              {{ item.name }}
            </router-link>
          </li>
        </ul>
      </div>

      <div class="navbar__mobile-menu-btn"
        :class="{ 'opened': isMobileMenuOpen }"
        @click="handleMobileMenuBtnClick">
        <img
          :src="isMobileMenuOpen ? '/images/icons/times.svg' : '/images/icons/hamburgerMenu.svg'">
      </div>

      <div
        class="navbar__mobile-menu"
        :class="{ 'opened': isMobileMenuOpen }">
          <scrollactive v-if="mobileMenuLinks"
            tag="ul" class="mobile-menu__links" :modifyUrl="false"
            :offset="100"
            v-on:itemchanged="onItemChanged">
            <li
              v-for="(item, i) in mobileMenuLinks" :key="i"
              class="mobile-menu__links-item">
                <a :href="item.anchor" class="scrollactive-item">{{ item.name }}</a>
            </li>
          </scrollactive>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    links: {
      type: Array,
      required: false,
    },
    mobileMenuLinks: {
      type: Array,
      required: false,
    },
  },
  components: {},
  data: () => ({
    isLogoVisible: false,
    isMobileMenuOpen: false,
    isScrolled: false,
  }),
  created() {
    this.$eventBus.$on('welcomeSectionScrolled', this.handleWelcomeSectionScroll);
    document.addEventListener('scroll', this.handleScroll);
  },
  computed: {},
  methods: {
    handleWelcomeSectionScroll(payload) {
      this.isLogoVisible = payload;
    },
    handleMobileMenuBtnClick() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    onItemChanged() {
      this.isMobileMenuOpen = false;
    },
    handleScroll() {
      if (window.scrollY > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
    handleLogoClick() {
      if (window.innerWidth > 992) {
        this.$eventBus.$emit('navbarLogoClicked');
      } else {
        this.$SmoothScroll(0);
      }
    },
    goToLastSection() {
      this.$eventBus.$emit('goToLastSection');
    },
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang='scss' scoped>
.navbar__wrapper {
  display: flex;
  align-items: center;
  padding: 0 77px 0 60px;
  width: 100%;
  height: 120px;
  overflow: hidden;

  @media screen and (max-height: 759px) {
    height: 80px;
  }

  @media screen and (max-width: 991px) {
    padding: 0 25px 0;
    height: 80px;
    border: 10px solid $mobile-border-color;
    border-bottom: 0;
    transition: $global-transition;
    background-color: transparent;

    &.scrolled {
      background-color: $mobile-border-color;
      box-shadow: 0 10px 20px rgba($black, 0.5);
    }
  }
}

.navbar__logo {
  position: relative;
  bottom: -100%;
  opacity: 0;
  transition: all 1.5s ease-in-out;

  @media screen and (max-height: 759px) {
    img {
      max-height: 55px;
    }
  }

  a {
    cursor: pointer;
  }

  &.mobile-menu-opened {
    z-index: 1;
    transition: none;
  }

  @media screen and (max-width: 991px) {
    bottom: 0;
    opacity: 1;
    transition: $global-transition;

    img {
      max-height: 35px;
    }

    .scrolled & {
      filter: grayscale(100%) invert(1);
    }
  }
}

.nabar__logo--visible {
  bottom: 0;
  opacity: 1;
}

.navbar__links {
  margin: 0 0 0 auto;

  @media screen and (max-width: 991px) {
    display: none;
  }
}

.navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-links__item {
  display: inline-block;
  margin: 0 45px 0 0;

  a,
  span {
    text-decoration: none;
    color: $purple;
    font-family: "Poppins-SemiBold";
    font-size: 16px;
    cursor: pointer;
    transition: $global-transition;

    &:hover {
      color: $white;
    }
  }

  &:last-child {
    margin: 0;
  }
}

.navbar__mobile-menu-btn,
.navbar__mobile-menu {
  display: none;

  @media screen and (max-width: 991px) {
    display: block;
  }
}

.navbar__mobile-menu-btn {
  cursor: pointer;
  width: 30px;
  height: 24px;
  margin: 0 0 0 auto;

  &.opened {
    z-index: 1;
  }

  &:focus,
  &:active {
    outline: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: center;
    transition: $global-transition;

    .scrolled & {
      filter: grayscale(100%) invert(1);
    }
  }
}

.navbar__mobile-menu {
  position: fixed;
  top: 10px;
  left: 0px;
  height: calc(100% - 10px);
  width: 100%;
  background-image: radial-gradient(ellipse at 0% -30%, #121B28 10%, #15121A 100%);
  transition: visibility 0.3s linear,opacity 0.3s linear;
  border: 10px solid #2b2460;
  border-top: 0;
  border-bottom: 0;
  visibility:hidden;
  opacity:0;

  &.opened {
    border-bottom: 10px solid #2b2460;
    visibility:visible;
    opacity:1;
  }
}

.mobile-menu__links {
  padding: 0;
  margin: 120px 0 0 30px;
  list-style-type: none;
}

.mobile-menu__links-item {
  margin: 0 0 20px;

  a {
    font-size: 16px;
    font-family: 'AkzidenzGrotesk-Bold';
    cursor: pointer;
    text-decoration: none;
    display: block;
    color: $global-font-color;

    @media screen and (min-width: 576px) {
      font-size: 22px;
    }

    &:hover {
      color: $purple;
    }

    &.is-active {
      color: $purple;
    }
  }

  &:last-child {
    margin: 0;
  }
}
</style>
