<template>
  <div id="app">
    <notification />
    <div class="background__text background__text--top">
      <img src="/images/background_text-top.svg">
    </div>

    <navbar
      :links="navbarItems"
      :mobileMenuLinks="navbarMobileItems" />

    <router-view />

    <div class="background__text background__text--bottom">
      <img src="/images/background_text-bottom.svg">
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/shared/Navbar.vue';
import Notification from '@/components/shared/Notification.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Notification,
  },
  computed: {
    navbarItems() {
      return [
        // TODO: uncomment if needed
        // {
        //   link: '/',
        //   name: this.$t('navbar.items.buildNow'),
        // },
        {
          link: '/',
          name: this.$t('navbar.items.contact'),
          isGoToBottom: true,
        },
        // {
        //   link: '/',
        //   name: this.$t('navbar.items.blog'),
        // },
      ];
    },
    navbarMobileItems() {
      return [
        {
          anchor: '#welcome-section',
          name: this.$t('navbar.mobileItems.home'),
        },
        {
          anchor: '#get-to-know-section',
          name: this.$t('navbar.mobileItems.getToKnow'),
        },
        {
          anchor: '#personalize-section',
          name: this.$t('navbar.mobileItems.personalize'),
        },
        {
          anchor: '#create-infrastructure-section',
          name: this.$t('navbar.mobileItems.infrastructure'),
        },
        {
          anchor: '#test-monitor-section',
          name: this.$t('navbar.mobileItems.testMonitor'),
        },
        {
          anchor: '#certificate-section',
          name: this.$t('navbar.mobileItems.certificate'),
        },
        {
          anchor: '#references-section',
          name: this.$t('navbar.mobileItems.references'),
        },
        {
          anchor: '#contact-section',
          name: this.$t('navbar.mobileItems.identity'),
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/css/global.scss';

#app {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100vh;

  @media screen and (max-width: 991px) {
    max-height: none;
    padding-top: 60px;
  }
}

#navbar {
  @media screen and (max-width: 991px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
}

.background__text {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}

.background__text--top {
  opacity: 0.06;
  max-height: 110px;
  object-fit: cover;
  object-position: bottom;

  @media screen and (max-width: 991px) {
    display: none;
  }
}

.background__text--bottom {
  opacity: 0.05;
  bottom: 0;
  max-height: 40px;
  object-fit: cover;
  object-position: top;

  @media screen and (max-width: 991px) {
    display: none;
  }
}
</style>
