<template>
  <div v-if="isVisible !== undefined" id='notification'>
    <div class="notification__wrapper animate__animated"
    :class="isVisible ? 'animate__fadeInDown' : 'animate__fadeOutUp'" @click="closeNotification">
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: {},
  components: {},
  data: () => ({
    isVisible: undefined,
    message: '',
  }),
  created() {
    this.$eventBus.$on('triggerNotification', this.showNotification);
  },
  computed: {},
  methods: {
    showNotification(msg) {
      this.isVisible = true;
      this.message = msg;
    },
    closeNotification() {
      this.isVisible = false;
    },
  },
  watch: {
    isVisible(value) {
      if (value) {
        setTimeout(() => {
          this.isVisible = false;
        }, 3000);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
#notification {
  position: fixed;
  top: 25px;
  left: 0;
  margin: 0 auto;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 991px) {
    top: 85px;
  }
}

.notification__wrapper {
  border: 15px solid rgba($purple, 0.3);
  color: $white;
  font-size: 14px;
  width: 450px;
  padding: 20px 30px;
  text-align: center;
  background-color: #121b28;
  cursor: pointer;
  position: absolute;

  @media screen and (max-width: 575px) {
    width: 320px;
  }
}
</style>
