import Vue from 'vue';
import i18n from '@/lang/i18n';
import VueFullpage from 'fullpage-vue';
import VueScrollactive from 'vue-scrollactive';
import App from './App.vue';
import store from './store';
import router from './router';
import 'animate.css';
import 'fullpage-vue/src/fullpage.css';

const vueSmoothScroll = require('vue-smoothscroll');

Vue.use(vueSmoothScroll);
Vue.use(VueFullpage);
Vue.config.productionTip = false;

Vue.use(VueScrollactive);

Vue.prototype.$eventBus = new Vue();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
